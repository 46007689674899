@import "~bootstrap/scss/bootstrap.scss";

.share-tile {
  .sm-label {
    font-size: $font-size-sm;
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
  .secondary {
    @extend .text-secondary;
    font-size: $font-size-sm;
  }
  .number {
    @include media-breakpoint-up(md) {
      text-align: right !important;
    }
  }
}