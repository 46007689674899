@import "~bootstrap/scss/bootstrap.scss";

.shares-list {
  .share-tile-headers {
    @include media-breakpoint-down(md) {
      display: none
    }
    font-weight: bold;
    @extend .mb-2;
    .number {
      @extend .text-end;
    }
    @extend .mb-4;
  }
  .share-tile {
    @extend .mb-4;
  }
}